@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }

  @page {
    size: auto;
    margin: 0mm;
  }
}

@media print {
  .patient-section-print {
    display: flex;
  }

  .shahreer-rx {
    margin-top: 20px !important;
  }

  .prescription-body-right {
    padding-right: 10px;
  }

  .blank-prescription-card-body {
    padding: 23px;
  }

  .blank-prescription-print-left {
    width: 32% !important;
    padding-left: 35px;
    margin-top: 10px;
  }

  .blank-prescription-print-right {
    width: 68% !important;
    padding-left: 30px;
    margin-top: 10px;
  }

  .print-card-body {
    padding-top: 20px;
  }

  .prescription-body-left {
    border-right: none;
  }

  .blank-header {
    margin: 8px 15px;
  }

  .no-pad-patient-section {
    border: 1px solid #d9d9d9;
    margin: 10px;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .print-phn-label {
    width: 0.9in !important;
  }

  .hide-in-print,
  .button-area,
  .new-pres-btn,
  .fa-times-circle,
  .cursor-pointer {
    display: none;
  }

  .hide-in-print {
    display: none;
  }

  .prescription-body-print {
    position: relative;
  }

  .bar-qr-code {
    margin-top: 20px !important;
    text-align: center;
  }

  .print-patient-id {
    /* width: 0.8in !important; */
    white-space: nowrap;
    width: 0.29in !important;
  }

  .patient-section {
    display: none;
  }

  .footer pre {
    border: none !important;
    text-align: center !important;
  }

  .blank-footer-print {
    position: fixed;
    bottom: 0;
    width: 100% !important;
  }

  .blank-footer-print pre {
    text-align: center !important;
  }

  .normal-card-body {
    display: none;
  }

  .print-card-body {
    margin: 0 auto;
    display: block;
    color: #1d1d1d !important;
  }

  .show-in-print {
    display: block !important;
    color: #1d1d1d !important;
  }

  .item-row {
    /* margin-bottom: -10px !important; */
    color: #1d1d1d !important;
  }

  .data-item-history {
    margin-bottom: 10px;
  }

  .sub-child-list {
    width: 100%;
    color: #1d1d1d !important;
    font-size: 15px;
    padding-left: 24px;
  }

  .rx-sub-child {
    padding-left: 5px !important;
  }

  .prescription-body-print,
  .patient-section-print,
  .patient-section {
    color: #000000 !important;
  }

  .prescription-item span {
    color: #000000 !important;
    font-size: 16px;
    font-weight: bold !important;
  }

  .sticky-footer {
    display: none !important;
  }

  .pdf-prescription .rx-body {
    margin-top: 0.075rem;
  }

  .pdf-prescription .card {
    border: none !important;
    box-shadow: none !important;
    background-color: #f8f9fc !important;
  }

  .pdf-prescription .footer-area {
    padding-bottom: 10px !important;
  }

  .relative-card-body {
    position: relative !important;
  }

  /* .signature-fixed {
        position: absolute !important;
        right: 20px !important;
        bottom: 10px !important;
    } */
  .signature-fixed {
    position: absolute;
  }
}
