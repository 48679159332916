body {
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
}

.col-md-7 {
  width: 80% !important;
}

.form-control {
  border-radius: 7px;
}

.form-group {
  padding-top: 6px;
}

.support-area {
  display: flex;
  justify-content: end;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 0;
}

.support-logo {
  height: 100px;
  width: 100px;
}

.signin-link,
.for-support {
  color: #0561b7;
  text-decoration: none;
}

.signin-link {
  cursor: pointer;
}

.support-number {
  font-size: smaller;
}

.image-text {
  padding-top: 48%;
  color: #ffffff;
}

.image-text-title {
  font-size: 27px;
  font-weight: bolder;
}

.image-text-title sup {
  vertical-align: super;
  font-size: smaller;
}

h2 {
  font-size: 20px;
}

.half,
.half .container > .row {
  height: 90vh;
  /* min-height: 900px;  edited by jakir */
  min-height: 500px;
}

@media (max-width: 991.98px) {
  .half .bg {
    height: 500px;
  }
}

.half .contents,
.half .bg {
  width: 50%;
}

@media (max-width: 1199.98px) {
  .half .contents,
  .half .bg {
    width: 100%;
  }
}

.half .bg {
  background-size: cover;
  background-position: center;
}

.half .forgot-pass {
  position: relative;
  top: 2px;
  font-size: 14px;
}

.login-form-area {
  float: none;
  margin: auto;
}

.forgot-password {
  text-align: right;
  color: #208a52;
  cursor: pointer;
}

.form-title {
  font-weight: bold;
}

.registration-area {
  position: relative;
}

.resend-btn {
  color: #0d6efd;
  font-weight: 100;
  cursor: pointer;
}

.form-area,
.registration-area {
  color: #000000;
}

.form-area label {
  margin-bottom: 0;
}

.form-area .form-group {
  padding-top: 3px;
}

.signup-btn {
  width: 70%;
}

.signin-btn-area button {
  width: 100% !important;
}

.reset-pass-btn button {
  width: 98%;
}

.forgot-pass-btn {
  display: flex;
  justify-content: space-around;
}

.forgot-pass-btn button {
  width: 45%;
}

.pass-show-hide {
  position: absolute;
  top: 14px;
  right: 12px;
  cursor: pointer;
}

.fa-eye-slash {
  color: #aeaeae;
}

@media only screen and (max-width: 768px) {
  /* Styles specific to mobile devices */
  .bg {
    display: flex;
    background-size: cover;
    background-position: center;
    flex-direction: column;
  }

  .space-10 {
    margin-top: 10px;
  }
}
